import React, { useState } from "react"
import { Box, Container, Grid, Tab, Tabs, Theme, Typography, useTheme } from "@material-ui/core";
import styled from "@emotion/styled";
import Section from "../components/section";
import Img from "gatsby-image";
import { StyledMarkdown } from "../components/styledComponents";
import StoreList, { StoreLink } from "./stores-list";

interface BookBlockProps {
  bookTeaser: string,
  purchaseTagline: string,
  bookImage: {
    childImageSharp: {
      fluid: import("gatsby-image").FluidObject
    }
  }
  usStores: Array<StoreLink>,
  ukStores: Array<StoreLink>
}

const TabStyled = styled(Tabs)((props: { theme: Theme }) => `
  border-bottom: 1px solid ${props.theme.palette.divider};
  height: 100%;
`);

const TabPanelDiv = styled.div`
  flex-grow: 1;
`;

const TabLabel = styled(Tab)`
  flex-grow: 1;
  font-size: 1.25rem;
`;

const BookImageContain = styled.div`
    margin: auto;
    max-width: 350px;
`;

const StoreImageContain = styled.div`
  width: 100%;
`;

const MinHeightGridItem = styled(Grid)`
  min-height: 100px;
`;

interface TabPanelProps {
  selected: number,
  index: number,

}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, selected, index, ...other } = props;

  return <TabPanelDiv
    role="tabpanel"
    hidden={selected !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {selected === index && <Box p={1} height="100%" display="flex">
      {children}
    </Box>}
  </TabPanelDiv>;
}

const BookBlock: React.FC<BookBlockProps> = (props) => {
  const theme = useTheme<Theme>();
  const [storeTab, setStoreTab] = useState(0);

  function tabProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return <Container maxWidth="lg">
      <Grid container alignItems="center">
        <Grid item xs={12} md={4}>
          <BookImageContain>
            <Img fluid={props.bookImage.childImageSharp.fluid} />
          </BookImageContain>
        </Grid>
        <Grid container item xs={12} md={8}>
          <Grid item xs={12}>
            <Box px={{ sm: 8 }} py={2}>
              <StyledMarkdown source={props.bookTeaser} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom>{props.purchaseTagline}</Typography>
          </Grid>
          <Grid item container xs={12}>
            {props.ukStores && props.ukStores.length > 0 &&
            <Grid item xs={12}>
              <TabStyled theme={theme}
                variant="scrollable"
                value={storeTab}
                onChange={(evt, value) => setStoreTab(value)}
                aria-label="Purchase Options"
              >
                <TabLabel label={"US"} {...tabProps(0)} />
                <TabLabel label={"UK"} {...tabProps(1)} />
              </TabStyled>
            </Grid>}
            <MinHeightGridItem item container xs={12}>
              <TabPanel selected={storeTab} index={0}>
                <StoreList stores={props.usStores} />
              </TabPanel>
              {props.ukStores && props.ukStores.length > 0 &&
              <TabPanel selected={storeTab} index={1}>
                <StoreList stores={props.ukStores} />
              </TabPanel> }
            </MinHeightGridItem>
          </Grid>
        </Grid>
      </Grid>
    </Container>;

}

export default BookBlock;