import React from "react"
import { Box, Grid, useTheme } from "@material-ui/core";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { ThemeWithAccent } from "../layouts/theme";

export interface StoreLink {
  link: string,
  alt: string,
  img: {
    childImageSharp: {
      fluid: import("gatsby-image").FluidObject
    }
  }
}

interface StoresListProps {
  stores: Array<StoreLink>,
}

const StoreImageContain = styled.div`
  width: 100%;
`;

const StoreList: React.FC<StoresListProps> = (props) => {
  const theme = useTheme<ThemeWithAccent>();

  return <Grid container justify="space-evenly" alignItems="center">
    {props.stores.map((s, idx) =>
      <Grid item key={idx} xs={6} sm={3} style={{alignSelf:"stretch"}}>
        <Box p={1} height="100%">
          <Box p={1} boxShadow={8} borderRadius={16} border={3} borderColor={theme.palette.secondary.dark} height="100%" display="flex" alignItems="center" >
            <StoreImageContain>
              <a href={s.link} target="__blank" rel="nofollow noopener">
                <Img fluid={s.img.childImageSharp.fluid} alt={s.alt}></Img>
              </a>
            </StoreImageContain>
          </Box>
        </Box>
      </Grid>
    )}
  </Grid>;

}

export default StoreList;